import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const tag = this.props.pageContext.tag
    const title = this.props.data.site.siteMetadata.title
    // const totalCount = this.props.data.allMarkdownRemark.totalCount
    // const tagHeader = `${totalCount} painting${
    //   totalCount === 1 ? '' : 's'
    // } tagged with “${tag}”`
    const tagHeader = `${tag} paintings`

    return (
      <Layout>
        <div className="has-text-centered is-size-2 py-6 color-purple">{tagHeader}</div>
        <section className="section">
          <Helmet title={`${tagHeader} | ${title}`} />
          <div className="container content">
            <div className="columns">
              <div
                className="column is-10 is-offset-1"
                style={{ marginBottom: '6rem' }}
              >

                <div className="container content" style={{paddingTop: 60}}>
                  <div className="columns is-multiline is-centered">
                    {posts && posts.map(({ node: painting }) => (
                      // <li key={post.node.fields.slug}>
                      //   <Link to={post.node.fields.slug}>
                      //     <h2 className="is-size-2">{post.node.frontmatter.title}</h2>
                      //   </Link>
                      // </li>

                      <div className="is-parent column is-4" key={painting.id}>
                        <article>
                          <div className="featured-thumbnail">
                            <Link to={painting.fields.slug}>
                              <PreviewCompatibleImage
                                imageInfo={{
                                  image: painting.frontmatter.featuredimage,
                                  alt: painting.frontmatter.title,
                                }}
                              />
                            </Link>
                          </div>
                        </article>
                      </div>

                    ))}
                  </div>
                </div>
                {/*<ul className="taglist">{postLinks}</ul>*/}
                {/*<p>*/}
                {/*  <Link to="/tags/">Browse all tags</Link>*/}
                {/*</p>*/}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            featuredimage {
                  childImageSharp {
                    fixed(width: 350, height: 350, quality: 100) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
          }
        }
      }
    }
  }
`
